import { Props } from 'docz';
import * as packageJson from "../../../../../packages/notifications/package.json";
import Playground from "../../../../../src/Playground";
import { UiNotifications } from "../../../../../packages/notifications/src";
import { NotificationsExample } from "../../../../../packages/notifications/docs/example";
import { UiBadge } from '@uireact/badge';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiNotifications,
  NotificationsExample,
  UiBadge,
  React
};