import { Props } from 'docz';
import { UiText } from '@uireact/text';
import * as packageJson from "../../../../../packages/tabs/package.json";
import Playground from "../../../../../src/Playground";
import { TabsExample } from "../../../../../packages/tabs/docs/utils";
import { UiTabs, UiTabItem } from "../../../../../packages/tabs/src";
import * as React from 'react';
export default {
  Props,
  UiText,
  packageJson,
  Playground,
  TabsExample,
  UiTabs,
  UiTabItem,
  React
};