import { Props } from 'docz';
import * as packageJson from "../../../../../packages/confirm-dialog/package.json";
import Playground from "../../../../../src/Playground";
import { UiConfirmDialog } from "../../../../../packages/confirm-dialog/src";
import { ConfirmDialogExample } from "../../../../../packages/confirm-dialog/docs/example";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiConfirmDialog,
  ConfirmDialogExample,
  React
};