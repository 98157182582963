export const BreakpointsSizes = {
  s: {
    max: 579,
  },
  m: {
    min: 580,
    max: 991,
  },
  l: {
    min: 992,
    max: 1440,
  },
  xl: {
    min: 1440,
  },
};
