import { Props } from 'docz';
import * as packageJson from "../../../../../packages/view/package.json";
import Playground from "../../../../../src/Playground";
import { UiView, UiViewRow } from "../../../../../packages/view/src";
import { ViewRowExample } from "../../../../../packages/view/docs/util";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiView,
  UiViewRow,
  ViewRowExample,
  React
};