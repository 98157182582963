import { Props } from 'docz';
import * as packageJson from "../../../../../packages/progress-indicator/package.json";
import Playground from "../../../../../src/Playground";
import { UiText } from '@uireact/text';
import { UiProgressIndicator, UiProgressIndicatorItem } from "../../../../../packages/progress-indicator/src";
import { ProgressIndicatorExample } from "../../../../../packages/progress-indicator/docs/utils";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiText,
  UiProgressIndicator,
  UiProgressIndicatorItem,
  ProgressIndicatorExample,
  React
};