import { Props } from 'docz';
import * as packageJson from "../../../../../packages/datepicker/package.json";
import Playground from "../../../../../src/Playground";
import { UiInputDatepicker } from "../../../../../packages/datepicker/src";
import { DatePickerInputExample } from "../../../../../packages/datepicker/docs/example";
import { UiBadge } from '@uireact/badge';
import { UiIcon } from '@uireact/icons';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiInputDatepicker,
  DatePickerInputExample,
  UiBadge,
  UiIcon,
  React
};