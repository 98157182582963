import { Props } from 'docz';
import * as packageJson from "../../../../../packages/separator/package.json";
import Playground from "../../../../../src/Playground";
import { UiLineSeparator, UiDiagonalSeparator, UiBubblesSeparator } from "../../../../../packages/separator/src";
import { UiBadge } from '@uireact/badge';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiLineSeparator,
  UiDiagonalSeparator,
  UiBubblesSeparator,
  UiBadge,
  React
};