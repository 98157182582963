import * as packageJson from "../../../../../packages/foundation/package.json";
import ThemeTokens from "../../../../../src/ThemeTokens";
import { DefaultTheme } from "../../../../../packages/foundation/src/themes";
import { UiLineSeparator } from '@uireact/separator';
import * as React from 'react';
export default {
  packageJson,
  ThemeTokens,
  DefaultTheme,
  UiLineSeparator,
  React
};