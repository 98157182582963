import { ExtendStyleExpample } from "../../../../../docs/helpers/extend-styling-example.tsx";
import { JustACard } from "../../../../../docs/helpers/just-a-card.tsx";
import { UiCard } from '@uireact/card';
import { UiLineSeparator } from '@uireact/separator';
import * as React from 'react';
export default {
  ExtendStyleExpample,
  JustACard,
  UiCard,
  UiLineSeparator,
  React
};