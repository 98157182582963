module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/uireact/uireact/.docz"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"mode":"dark","showDarkModeSwitch":true,"showMarkdownEditButton":false},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Documentation","Contributions",""],"mdPlugins":[],"hastPlugins":[],"ignore":["utils/**/*","packages/**/CHANGELOG.md","packages/**/README.md","examples/**/*","README.md"],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/home/runner/work/uireact/uireact/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"main","eb":"master","edit-branch":"master","config":"","title":"@uireact","description":"Themed library of components for react development","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/runner/work/uireact/uireact","templates":"/home/runner/work/uireact/uireact/node_modules/docz-core/dist/templates","docz":"/home/runner/work/uireact/uireact/.docz","cache":"/home/runner/work/uireact/uireact/.docz/.cache","app":"/home/runner/work/uireact/uireact/.docz/app","appPackageJson":"/home/runner/work/uireact/uireact/package.json","appTsConfig":"/home/runner/work/uireact/uireact/tsconfig.json","gatsbyConfig":"/home/runner/work/uireact/uireact/gatsby-config.js","gatsbyBrowser":"/home/runner/work/uireact/uireact/gatsby-browser.js","gatsbyNode":"/home/runner/work/uireact/uireact/gatsby-node.js","gatsbySSR":"/home/runner/work/uireact/uireact/gatsby-ssr.js","importsJs":"/home/runner/work/uireact/uireact/.docz/app/imports.js","rootJs":"/home/runner/work/uireact/uireact/.docz/app/root.jsx","indexJs":"/home/runner/work/uireact/uireact/.docz/app/index.jsx","indexHtml":"/home/runner/work/uireact/uireact/.docz/app/index.html","db":"/home/runner/work/uireact/uireact/.docz/app/db.json"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    }]
