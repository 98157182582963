import * as packageJson from "../../../../../packages/foundation/package.json";
import { Props } from 'docz';
import Playground from "../../../../../src/Playground";
import { UiSpacing, Sizing } from "../../../../../packages/foundation/src";
import * as React from 'react';
export default {
  packageJson,
  Props,
  Playground,
  UiSpacing,
  Sizing,
  React
};