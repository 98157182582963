import { Props } from 'docz';
import * as packageJson from "../../../../../packages/tooltip/package.json";
import Playground from "../../../../../src/Playground";
import { UiTooltip } from '@uireact/tooltip';
import { UiText } from '@uireact/text';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiTooltip,
  UiText,
  React
};