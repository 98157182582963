import { Props } from 'docz';
import { UiSpacing, TextSize } from '@uireact/foundation';
import { UiIcon } from '@uireact/icons';
import * as packageJson from "../../../../../packages/badge/package.json";
import Playground from "../../../../../src/Playground";
import { UiBadge } from "../../../../../packages/badge/src";
import * as React from 'react';
export default {
  Props,
  UiSpacing,
  TextSize,
  UiIcon,
  packageJson,
  Playground,
  UiBadge,
  React
};