import { Props } from 'docz';
import { MemoryRouter } from 'react-router-dom';
import * as packageJson from "../../../../../packages/text/package.json";
import Playground from "../../../../../src/Playground";
import { UiButtonLink } from "../../../../../packages/text/src";
import { UiIcon } from '@uireact/icons';
import { UiSpacing } from '@uireact/foundation';
import * as React from 'react';
export default {
  Props,
  MemoryRouter,
  packageJson,
  Playground,
  UiButtonLink,
  UiIcon,
  UiSpacing,
  React
};