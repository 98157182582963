import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiFlexGrid } from '@uireact/flex';
import { UiText } from '@uireact/text';
import Playground from "../../../../../src/Playground";
import { UiButton } from "../../../../../packages/button/src";
import { UiSpacing, Sizing } from "../../../../../packages/foundation/src";
import { UiIcon } from "../../../../../packages/icons/src";
import { UiGrid } from "../../../../../packages/grid/src";
import * as packageJson from "../../../../../packages/button/package.json";
import * as React from 'react';
export default {
  Props,
  UiCard,
  UiFlexGrid,
  UiText,
  Playground,
  UiButton,
  UiSpacing,
  Sizing,
  UiIcon,
  UiGrid,
  packageJson,
  React
};