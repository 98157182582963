import { Props } from 'docz';
import { UiText, UiHeading } from '@uireact/text';
import { UiSpacing, ColorTokens, ColorCategory } from '@uireact/foundation';
import Playground from "../../../../../src/Playground";
import { UiCard } from "../../../../../packages/card/src";
import * as packageJson from "../../../../../packages/card/package.json";
import * as React from 'react';
export default {
  Props,
  UiText,
  UiHeading,
  UiSpacing,
  ColorTokens,
  ColorCategory,
  Playground,
  UiCard,
  packageJson,
  React
};