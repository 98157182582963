import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBrandsMicrosoftEdge = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="m12.079 0 .109.001c2.948.027 5.879 1.086 8.041 2.905 2.285 1.923 3.544 4.533 3.544 7.348 0 2.951-2.873 5.352-6.404 5.352a7.43 7.43 0 0 1-2.557-.444.696.696 0 0 1-.268-1.128c.559-.614.867-1.41.867-2.241 0-.798-.31-1.645-.62-2.372a7.325 7.325 0 0 0-1.46-2.228C11.997 5.824 10.17 5.13 7.901 5.13c-2.27 0-5.346.566-7.105 3.26a.683.683 0 0 0-.018.028C2.234 3.548 6.757 0 12.079 0z" />
    <path d="M3.643 20.511a11.707 11.707 0 0 1-3.415-8.404c.004-.069.071-1.198.721-2.442.196-.375.432-.732.7-1.06C2.835 7.159 4.637 6.442 6.464 6.24c1.807-.2 3.751-.044 5.322.951l.005.003c.852.542 1.578 1.352 2.061 2.237l.002.003a3.327 3.327 0 0 0-1.872-.554c-.537.006-1.053.174-1.546.374-1.568.639-2.789 1.827-3.544 3.454-.739 1.593-.952 3.463-.6 5.266.358 1.831 1.289 3.452 2.623 4.565.352.294.735.555 1.136.776a7.776 7.776 0 0 0 1.655.672l.038.011a11.692 11.692 0 0 1-8.101-3.487z" />
    <path d="M22.417 18.496a11.884 11.884 0 0 1-7.283 5.198 9.867 9.867 0 0 1-.973-.001c-1.276-.064-2.55-.396-3.646-1.064a6.557 6.557 0 0 1-.784-.561c-1.225-1.023-2.082-2.518-2.413-4.21-.328-1.677-.131-3.413.554-4.889a6.596 6.596 0 0 1 1.042-1.602c.156-.176.323-.343.499-.499a3.416 3.416 0 0 0-.304 1.471c.039 1.938.959 3.751 2.592 5.106 1.62 1.344 3.761 2.085 6.028 2.085 1.635 0 3.226-.39 4.601-1.128.012-.006.044-.023.079.014s.015.068.008.08z" />
  </svg>
);
export default SvgBrandsMicrosoftEdge;
