import { Props } from 'docz';
import * as packageJson from "../../../../../packages/header/package.json";
import Playground from "../../../../../src/Playground";
import { UiHeader } from "../../../../../packages/header/src";
import { UiSpacing, Sizing } from "../../../../../packages/foundation/src";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiHeader,
  UiSpacing,
  Sizing,
  React
};