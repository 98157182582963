import { Props } from 'docz';
import * as packageJson from "../../../../../packages/icons/package.json";
import Playground from "../../../../../src/Playground";
import { UiIcon } from "../../../../../packages/icons/src";
import { IconsList } from "../../../../../packages/icons/docs/utils";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiIcon,
  IconsList,
  React
};