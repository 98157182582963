import { Props } from 'docz';
import * as packageJson from "../../../../../packages/table/package.json";
import Playground from "../../../../../src/Playground";
import { UiTable } from "../../../../../packages/table/src";
import { UiBadge } from '@uireact/badge';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiTable,
  UiBadge,
  React
};