import { Props } from 'docz';
import { UiText } from '@uireact/text';
import { UiSpacing } from '@uireact/foundation';
import * as packageJson from "../../../../../packages/menu/package.json";
import Playground from "../../../../../src/Playground";
import { MenuExample } from "../../../../../packages/menu/docs/utils";
import { UiMenu } from "../../../../../packages/menu/src";
import * as React from 'react';
export default {
  Props,
  UiText,
  UiSpacing,
  packageJson,
  Playground,
  MenuExample,
  UiMenu,
  React
};