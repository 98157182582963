import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBrandsGithub = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 .296c-6.627 0-12 5.372-12 12 0 5.302 3.438 9.8 8.206 11.387.6.111.82-.26.82-.577 0-.286-.011-1.231-.016-2.234-3.338.726-4.043-1.416-4.043-1.416-.546-1.387-1.332-1.756-1.332-1.756-1.089-.745.082-.729.082-.729 1.205.085 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.108-.776.419-1.305.762-1.605-2.665-.303-5.467-1.332-5.467-5.93 0-1.31.469-2.381 1.237-3.221-.125-.303-.536-1.523.116-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0 1 12 6.099c1.02.005 2.047.138 3.006.404 2.29-1.553 3.297-1.23 3.297-1.23.653 1.653.242 2.873.118 3.176.769.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.814 1.103.814 2.222 0 1.606-.014 2.898-.014 3.293 0 .319.216.694.824.576 4.766-1.589 8.2-6.085 8.2-11.385C24 5.669 18.627.296 12 .296z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path d="M4.545 17.526c-.026.06-.12.078-.206.037-.087-.039-.136-.121-.108-.18.026-.061.12-.078.207-.037.087.038.137.12.107.18zM5.031 18.068c-.057.053-.169.028-.245-.055-.079-.084-.093-.196-.035-.249.059-.053.167-.028.246.056.079.083.094.194.034.248zM5.504 18.759c-.074.051-.194.003-.268-.103-.074-.107-.074-.235.002-.286.074-.051.193-.005.268.101.073.108.073.236-.002.288zM6.152 19.427c-.066.073-.206.053-.308-.046-.105-.097-.134-.234-.068-.307.067-.073.208-.052.311.046.104.097.135.235.065.307zM7.047 19.814c-.029.094-.164.137-.3.097-.136-.041-.225-.151-.197-.246.028-.095.164-.139.301-.096.135.04.224.15.196.245zM8.029 19.886c.003.099-.112.181-.255.183-.143.003-.26-.077-.261-.174 0-.1.113-.181.256-.184.143-.003.26.077.26.175zM8.943 19.731c.017.096-.082.196-.224.222-.139.026-.268-.034-.286-.13-.017-.099.084-.198.223-.224.141-.025.269.033.287.132z" />
  </svg>
);
export default SvgBrandsGithub;
