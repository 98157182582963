import { Props } from 'docz';
import * as packageJson from "../../../../../packages/dialog/package.json";
import Playground from "../../../../../src/Playground";
import { UiDialog, UiDialogType } from "../../../../../packages/dialog/src";
import { DialogsExample } from "../../../../../packages/dialog/docs/utils";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiDialog,
  UiDialogType,
  DialogsExample,
  React
};