import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBrandsImdb = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M22.628 6.126H1.371C.615 6.126 0 6.742 0 7.498v9.003c0 .757.615 1.372 1.371 1.372h21.257c.756 0 1.372-.615 1.372-1.372V7.498c0-.756-.615-1.372-1.372-1.372zm0 .243c.624 0 1.129.505 1.129 1.129v9.003c0 .625-.504 1.129-1.129 1.129H1.371a1.128 1.128 0 0 1-1.129-1.129V7.498c0-.624.505-1.129 1.129-1.129h21.257z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path
      d="M3.456 8.614h1.776v6.851H3.456zM9.022 8.614l-.412 3.202-.252-1.74a48.7 48.7 0 0 0-.215-1.462H5.839v6.851h1.557l.007-4.523.654 4.523h1.109l.624-4.623.003 4.623h1.553V8.614H9.022zM14.124 9.85c.069.04.116.103.136.193.02.086.03.285.03.594v2.653c0 .459-.03.738-.09.841-.06.103-.216.152-.471.152V9.787c.193 0 .325.02.395.063zm-.016 5.616c.425 0 .744-.023.956-.07a1.22 1.22 0 0 0 .531-.246.918.918 0 0 0 .302-.485c.06-.206.093-.618.093-1.232v-2.404c0-.648-.027-1.083-.063-1.305a1.222 1.222 0 0 0-.299-.601c-.156-.18-.388-.309-.691-.389-.302-.08-.8-.12-1.66-.12h-1.325v6.851h2.156zM18.908 13.805c0 .329-.017.541-.05.627-.033.087-.176.129-.286.129-.106 0-.176-.039-.212-.126-.033-.083-.053-.276-.053-.578v-1.81c0-.312.016-.505.047-.585.033-.076.103-.116.206-.116.11 0 .255.047.292.133.036.09.056.279.056.568v1.758zm-2.314-5.191v6.851h1.6l.113-.435c.143.176.302.305.478.395.176.086.435.129.637.129a1.2 1.2 0 0 0 .727-.222c.206-.146.336-.322.389-.522.056-.202.083-.508.083-.917v-1.922c0-.415-.007-.684-.027-.81a.924.924 0 0 0-.558-.698 1.584 1.584 0 0 0-.614-.11c-.202 0-.465.04-.64.123a1.423 1.423 0 0 0-.475.365V8.614h-1.713z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
  </svg>
);
export default SvgBrandsImdb;
