import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBrandsWikipedia = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M24 4.656a.384.384 0 0 1-.08.236c-.055.07-.112.106-.177.106-.526.051-.958.22-1.292.509-.336.287-.68.838-1.036 1.649l-5.446 12.272c-.036.114-.135.171-.3.171a.336.336 0 0 1-.3-.171l-3.054-6.387-3.512 6.387a.336.336 0 0 1-.3.171c-.156 0-.26-.057-.31-.171L2.841 7.156c-.333-.762-.686-1.294-1.057-1.596-.37-.302-.887-.49-1.548-.562-.057 0-.112-.03-.16-.091A.304.304 0 0 1 0 4.701c0-.201.057-.3.171-.3.477 0 .975.021 1.497.063.483.045.939.066 1.365.066.435 0 .948-.021 1.539-.065a24.574 24.574 0 0 1 1.644-.063c.114 0 .171.099.171.3 0 .198-.036.298-.106.298-.476.034-.852.157-1.126.361a.97.97 0 0 0-.412.813c0 .171.057.384.171.64L9.336 16.8l2.51-4.741-2.339-4.903c-.42-.874-.766-1.44-1.036-1.693-.27-.251-.68-.407-1.228-.464-.051 0-.097-.03-.144-.091a.33.33 0 0 1-.07-.207c0-.201.049-.3.15-.3.477 0 .914.021 1.313.063.384.045.793.066 1.228.066.426 0 .878-.021 1.355-.065a17.05 17.05 0 0 1 1.452-.063c.114 0 .171.099.171.3 0 .198-.034.298-.106.298-.954.065-1.431.336-1.431.813 0 .213.11.545.331.992l1.547 3.141 1.539-2.873c.215-.407.322-.749.322-1.028 0-.654-.477-1.003-1.431-1.047-.087 0-.129-.099-.129-.298 0-.072.021-.139.063-.203.044-.065.087-.097.129-.097.342 0 .762.021 1.26.063.477.044.87.065 1.176.065.22 0 .545-.019.971-.055.54-.049.994-.074 1.357-.074.084 0 .127.084.127.255 0 .228-.078.342-.234.342-.555.057-1.003.211-1.34.46-.338.249-.76.815-1.264 1.697l-2.052 3.793 2.778 5.659 4.101-9.537c.141-.348.213-.669.213-.96 0-.699-.477-1.068-1.431-1.112-.087 0-.129-.099-.129-.298 0-.201.063-.3.192-.3.348 0 .762.021 1.239.063a11.5 11.5 0 0 0 1.11.065c.315 0 .678-.021 1.089-.065a11.94 11.94 0 0 1 1.155-.063c.099.003.15.087.15.258z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
  </svg>
);
export default SvgBrandsWikipedia;
