import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBrandsCcAmazonPay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M21.694 16.523c-2.628 1.939-6.437 2.97-9.717 2.97-4.598 0-8.738-1.7-11.87-4.528-.246-.222-.027-.526.269-.353 3.38 1.967 7.56 3.151 11.877 3.151 2.912 0 6.114-.604 9.06-1.854.443-.188.815.293.381.614m1.093-1.248c.336.431-.374 2.204-.691 2.996-.096.24.11.336.327.155 1.409-1.179 1.774-3.65 1.485-4.007-.286-.354-2.751-.658-4.255.398-.232.162-.192.387.065.356.847-.102 2.733-.329 3.069.102"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
    />
    <path
      d="m9.915 12.1 2.735-6.961a.973.973 0 0 0 .087-.298c0-.099-.058-.149-.174-.149h-.696c-.133 0-.223.021-.274.062-.05.042-.099.133-.149.274L9.765 9.85l-1.74-4.823c-.05-.141-.099-.232-.149-.274-.05-.041-.141-.062-.274-.062h-.746c-.116 0-.174.05-.174.149 0 .058.029.157.087.298l2.399 5.917-.236.635c-.141.398-.298.672-.472.821s-.419.223-.733.223c-.141 0-.253-.009-.335-.025a1.107 1.107 0 0 0-.187-.025c-.124 0-.187.079-.187.236v.323c0 .116.021.201.062.255a.351.351 0 0 0 .199.118c.207.057.443.087.708.087.472 0 .856-.124 1.15-.373.296-.248.555-.658.778-1.23M-.295 6.402a3.003 3.003 0 0 0-.516-1.025 2.238 2.238 0 0 0-.82-.647 2.55 2.55 0 0 0-1.082-.223c-.373 0-.738.07-1.094.211a3.49 3.49 0 0 0-1.007.622l-.062-.398c-.024-.167-.12-.25-.286-.25h-.547c-.165 0-.248.083-.248.248v8.366c0 .166.083.248.248.248h.733c.166 0 .248-.083.248-.248v-2.909a2.77 2.77 0 0 0 1.927.733c.406 0 .775-.081 1.106-.242.331-.162.613-.388.845-.678.232-.29.413-.642.541-1.057.129-.414.193-.879.193-1.392a4.684 4.684 0 0 0-.179-1.359zm-2.79 3.735c-.564 0-1.111-.198-1.641-.597V6.072c.522-.381 1.077-.572 1.666-.572 1.127 0 1.691.775 1.691 2.324-.001 1.543-.573 2.313-1.716 2.313zM5.311 4.995c-.373-.323-.953-.485-1.74-.485-.389 0-.779.036-1.168.105-.39.071-.717.164-.982.28a.47.47 0 0 0-.2.131c-.033.046-.05.127-.05.242v.335c0 .149.054.224.162.224.033 0 .069-.006.106-.019l.093-.031a6.41 6.41 0 0 1 1.828-.286c.497 0 .845.091 1.044.274.198.183.298.497.298.945v.82a6.696 6.696 0 0 0-1.567-.211c-.729 0-1.309.18-1.74.541-.431.36-.647.843-.647 1.447 0 .564.174 1.013.522 1.349.348.336.82.503 1.417.503.356 0 .715-.071 1.075-.211.361-.14.69-.34.988-.597l.062.41c.025.157.116.236.274.236h.534c.165 0 .249-.083.249-.248v-4.25c.001-.68-.186-1.181-.558-1.504zm-.609 4.6a3.21 3.21 0 0 1-.858.472 2.515 2.515 0 0 1-.858.162c-.323 0-.572-.085-.746-.255-.174-.17-.26-.412-.26-.727 0-.721.468-1.082 1.404-1.082.215 0 .437.015.665.044.228.029.446.069.653.118v1.268z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      transform="translate(9.067)"
    />
  </svg>
);
export default SvgBrandsCcAmazonPay;
