import { Props } from 'docz';
import * as packageJson from "../../../../../packages/form/package.json";
import Playground from "../../../../../src/Playground";
import { UiInput } from "../../../../../packages/form/src";
import { UiIcon } from '@uireact/icons';
import { UiSpacing } from '@uireact/foundation';
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiInput,
  UiIcon,
  UiSpacing,
  React
};