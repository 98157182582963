import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiText } from '@uireact/text';
import * as packageJson from "../../../../../packages/flex/package.json";
import Playground from "../../../../../src/Playground";
import { UiFlexGrid, UiFlexGridItem } from "../../../../../packages/flex/src";
import * as React from 'react';
export default {
  Props,
  UiCard,
  UiText,
  packageJson,
  Playground,
  UiFlexGrid,
  UiFlexGridItem,
  React
};