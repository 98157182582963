import { Props } from 'docz';
import * as packageJson from "../../../../../packages/expando/package.json";
import Playground from "../../../../../src/Playground";
import { UiExpandoText } from "../../../../../packages/expando/src";
import * as React from 'react';
export default {
  Props,
  packageJson,
  Playground,
  UiExpandoText,
  React
};